import { render, staticRenderFns } from "./EGISZList.vue?vue&type=template&id=4a5b80bc&scoped=true&"
import script from "./EGISZList.vue?vue&type=script&lang=js&"
export * from "./EGISZList.vue?vue&type=script&lang=js&"
import style0 from "./EGISZList.vue?vue&type=style&index=0&id=4a5b80bc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a5b80bc",
  null
  
)

export default component.exports