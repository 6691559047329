<template>
  <b-modal id="egisz-form-document-modal"
           size="xl"
           title="Данные для ЕГИСЗ"
           centered
           @onHide="$emit('hide')"
           hide-footer>
    <EGISZForm @success="$bvModal.hide('egisz-form-document-modal')"
               :record-id="recordId"/>
  </b-modal>
</template>

<script>
import EGISZForm from "@/components/parts/patients/EGISZForm.vue"

export default {
  name: "EGISZFormModal",
  components: {
    EGISZForm
  },
  props: {
    recordId: {
      type: Number,
      required: true
    }
  },
}
</script>

<style scoped>

</style>
