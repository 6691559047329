<template>
  <b-modal id="sign-document-modal"
           :title="record ? 'Подпись документа' : 'Подпись документов'"
           centered
           size="sm"
           @show="onShow"
           hide-footer>
    <ul v-if="record">
      <li class="mb-2">Скачать файл: <a class="text-primary pointer" @click="$emit('viewCDA')">ссылка</a></li>
      <li class="mb-2">В КриптоАРМ подписываем файл как указано в данной инструкции (<a href="https://api.n3health.ru/ecpdoc/" target="_blank">https://api.n3health.ru/ecpdoc/</a>)</li>
      <li>Загружаем подпись в поле ниже и нажимаем подписать:</li>
    </ul>
    <div class="alert alert-warning" v-if="records.length > 1">Имена файлов должны соответствовать формату <b>cda_xxxxxxx.xml.sig</b></div>
    <input type="file"
           class="form-control form-control-file"
           accept=".sig"
           :multiple="record ? false : true"
           @change="processFiles($event)"/>
    <div v-if="signatures.length && !record" class="mt-2">
      <div>Найдены подписи для следующих записей:</div>
      <div>{{ signatures.map(s => s.record.id).join(', ') }}</div>
    </div>
    <validation-error validationId="signature"/>
    <b-button class="btn-themed btn-themed-squared w-100 mt-5"
              :disabled="loading || !signatures.length"
              @click="sign">
      {{ $t('sign') }}
    </b-button>
  </b-modal>
</template>

<script>
import { CalendarService } from "@/services/api.service"
import ValidationError from "@/components/form/ValidationError.vue"

export default {
  name: "EgiszSignModal",
  components: {
    ValidationError
  },
  props: {
    records: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'doctor'
    }
  },
  data() {
    return {
      loading: false,
      signatures: []
    }
  },
  computed: {
    record() {
      return this.records.length === 1 ? this.records[0] : null
    },
  },
  methods: {
    async sign() {
      try {
        this.loading = true
        for(const signature of this.signatures) {
          const res = await CalendarService.signCDA(signature.record.id, {
            signature: signature.signature,
            status: signature.record.status
          })
          console.log(res.data)
        }
        this.$emit('signed')
        this.$bvModal.hide('sign-document-modal')
      } finally {
        this.loading = false
      }
    },
    processFiles(e) {
      this.signatures = []
      const files = e.target.files
      for(const file of files) {
        this.processFile(file)
      }
    },
    processFile(file) {
      let reader = new FileReader()
      reader.readAsText(file, "UTF-8")
      reader.onload = (evt) => {
        const signature = (evt.target.result).replace('-----BEGIN CMS-----', '').replace('-----END CMS-----', '').replace(' ', '').replace(/(\r\n|\n|\r)/gm, "")
        const record = this.record || this.findRecordFromFilename(file.name)
        if(record) {
          this.signatures.push({
            signature,
            record: this.record || this.findRecordFromFilename(file.name)
          })
        }
      }
      reader.onerror = (evt) => {
        console.log('error', evt)
      }
    },
    findRecordFromFilename(fileName) {
      const id = fileName.replace('cda_', '').replace('.xml.sig', '')
      return this.records.find(record => record.id === Number(id))
    },
    onShow() {
      this.signatures = []
      this.$store.commit('cleanValidationErrors')
    }
  }
}
</script>
