<template>
  <section id="egisz" class="egisz">
    <div class="section-top-row">
      <div class="row row-narrow align-items-center no-print position-relative">
        <div class="col-9 col-md order-md-0 order-0">
          <h1>{{ $t('egisz') }}</h1>
        </div>
      </div>
    </div>
    <div style="overflow-x: scroll" class="pt-3">
      <b-row class="flex-nowrap"
             style="gap: 16px"
             no-gutters>
        <b-col v-for="(s, sind) in egiszStatuses"
               :key="`e-${sind}`">
          <div class="column">
            <div>
              <div style="height: 26px;">
              <div v-if="['requires_attention', 'signed_by_doctor'].includes(s.id) && records[s.id] && records[s.id].length">
                <a class="pointer text-black text-underlined" @click="downloadAll(s.id)">{{ $t('download_all') }}</a> |
                <a class="pointer text-black text-underlined" @click="signAll(s.id)">{{ $t('sign_all') }}</a>
              </div>
              </div>
              {{ $t(s.title) }} | <span :class="{ 'text-danger': !!records[s.id] }">{{ records[s.id] ? records[s.id].length : 0 }}</span>
              <div class="separator w-100 mt-2 mb-3" :style="`background-color: ${s.color}`"/>
            </div>

            <template v-for="(egiszRecord, ind) in records[s.id]">
              <div
                  v-if="egiszRecord.record.patient && egiszRecord.record.doctor"
                  class="record-card card p-md-3 p-2 mb-3 pointer"
                  :style="`border-color: ${s.color}`"
                  :key="`record-id-${egiszRecord.id}`">
                <div class="text-right" v-if="editable(egiszRecord) || deletable(egiszRecord) || vieweable(egiszRecord) || cancelable(egiszRecord)">
                  <a class="p-2"
                     :title="$t('edit')"
                     v-b-tooltip.hover
                     v-if="editable(egiszRecord)"
                     @click="editRecord(egiszRecord, s.id, ind)">
                    <EditSvg class="svg-light pointer"/>
                  </a>
                  <a class="p-2"
                     :title="$t('view')"
                     v-b-tooltip.hover
                     v-if="vieweable(egiszRecord)">
                    <b-dropdown right variant="link" no-caret>
                      <template #button-content>
                        <EyeSvg class="svg-light pointer"/>
                      </template>
                      <b-dropdown-item @click="viewCDA(egiszRecord)">CDA</b-dropdown-item>
                      <b-dropdown-item @click="viewXML(egiszRecord)">XML</b-dropdown-item>
                    </b-dropdown>

                  </a>
                  <a class="p-2"
                     :title="$t('to_rework')"
                     v-b-tooltip.hover
                     v-if="cancelable(egiszRecord)"
                     @click="resetRecord(egiszRecord, s.id, ind)">
                    <BlockSvg class="pointer"/>
                  </a>
                  <a class="p-2"
                     :title="$t('delete')"
                     v-b-tooltip.hover
                     v-if="deletable(egiszRecord)"
                     @click="deleteRecord(egiszRecord, s.id, ind)">
                    <TrashSvg class="svg-light pointer"/>
                  </a>
                </div>
                <div class="mt-3">
                  <div>[{{ egiszRecord.id }}] {{ egiszRecord.record.patient.full_name }}</div>
                  <div>{{ $t('date_of_birth') }}: {{ egiszRecord.record.patient.date_of_birth | formatDate }}</div>
                  <div>{{ $t('phone') }}: {{ egiszRecord.record.patient.phone }}</div>
                  <div>{{ $t('visit_date') }}: {{ egiszRecord.record.readableStart }}</div>
                  <div>{{ $t('treating_doctor') }}: {{ egiszRecord.record.doctor.full_name }}</div>
                </div>
                <div class="mt-4" v-if="hasBtn(egiszRecord)">
                  <b-button class="btn-themed btn-themed-squared w-100"
                            :disabled="loading"
                            @click="nextStep(egiszRecord, s.id, ind)">
                    {{ $t(btnTitle(egiszRecord)) }}
                  </b-button>
                </div>
              </div>
            </template>
          </div>
        </b-col>
      </b-row>
      <EgiszSignModal :records="recordsForSignature"
                      @signed="loadList"
                      @viewCDA="viewCDA(recordForSignature)"/>
      <EGISZFormModal v-if="recordForEdit"
                      @hide="recordForEdit = null"
                      :record-id="recordForEdit.id"/>
    </div>
  </section>
</template>

<script>
import { egiszStatuses } from "@/dictionaries/dictionary"
import options from "@/data/patients/egiszrecord"
import { CalendarService, EloquentService } from "@/services/api.service"
import EditSvg from '@/assets/svg/general/edit.svg'
import EyeSvg from '@/assets/svg/form/eye.svg'
import TrashSvg from '@/assets/svg/general/delete.svg'
import BlockSvg from '@/assets/svg/general/block.svg'
import EgiszSignModal from "@/components/parts/patients/EgiszSignModal.vue"
import EGISZFormModal from "@/components/parts/patients/EGISZFormModal.vue"
import {mapState} from "vuex"

export default {
  name: "EGISZList",
  metaInfo() {
    return {
      title: this.$t("egisz"),
      meta: []
    }
  },
  components: {
    EGISZFormModal,
    EgiszSignModal,
    EditSvg,
    EyeSvg,
    TrashSvg,
    BlockSvg
  },
  data() {
    return {
      egiszStatuses,
      records: {},
      options,
      loading: false,
      recordsForSignature: [],
      recordForEdit: null
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
      user: state => state.auth.user,
    }),
    recordForSignature() {
      return this.recordsForSignature?.length ? this.recordsForSignature[0] : null
    }
  },
  methods: {
    async loadList() {
      const res = await CalendarService.getEGISZList()
      this.records = res.data
    },
    hasBtn(record) {
      if(record.status === 'draft' && this.rights.includes('egisz_edit')) return true
      if(record.status === 'requires_attention') {
        if(this.rights.includes('egisz_sign_for_others')) return true
        if(this.rights.includes('egisz_sign_own') && record.record.doctor_id === this.user.id) return true
      }
      if(record.status === 'signed_by_doctor' && this.rights.includes('egisz_sign_final')) return true
      if(record.status === 'signed_by_main_doctor' && this.rights.includes('egisz_sign_final')) return true
      return false
    },
    editable(record) {
      if(!this.rights.includes('egisz_edit')) return false
      return ['draft', 'requires_attention'].includes(record.status)
    },
    deletable(record) {
      if(!this.rights.includes('egisz_delete')) return false
      return ['draft', 'requires_attention', 'signed_by_doctor', 'signed_by_main_doctor'].includes(record.status)
    },
    vieweable(record) {
      return ['draft', 'requires_attention', 'signed_by_doctor', 'signed_by_main_doctor', 'sent_to_egisz'].includes(record.status)
    },
    cancelable(record) {
      if(!this.rights.includes('egisz_delete')) return false
      return ['requires_attention', 'signed_by_doctor', 'signed_by_main_doctor'].includes(record.status)
    },
    btnTitle(record) {
      const arr = {
        'draft': 'to_sign',
        'requires_attention': 'sign',
        'signed_by_doctor': 'sign',
        'signed_by_main_doctor': 'send_to_egisz',
        'sent_to_egisz': '-',
      }
      return arr[record.status]
    },
    async editRecord(record) {
      this.recordForEdit = record
      await this.$nextTick()
      this.$bvModal.show('egisz-form-document-modal')
    },
    async resetRecord(record, statusId, index) {
      try {
        this.loading = true
        const res = await CalendarService.resetEGISZ(record.id)
        if(res.data.id) {
          this.records[statusId].splice(index, 1)
          if(!this.records[res.data.status]) this.records[res.data.status] = []
          this.records[res.data.status].push(res.data)
        }
      } finally {
        this.loading = false
      }
    },
    async deleteRecord(record, statusId, index) {
      const answer = confirm(this.$t('are_you_sure_you_want_to_delete'))
      if(!answer) return
      try {
        this.loading = true
        await EloquentService.delete('egiszRecord', record.id)
        this.records[statusId].splice(index, 1)
      } finally {
        this.loading = false
      }
    },
    async nextStep(record, statusId, index, force = false) {
      if(['requires_attention','signed_by_doctor'].includes(record.status) && !force) {
        try {
          this.loading = true
          const res = await CalendarService.validateEGISZ(record.id)
          if(res.data.id) {
            this.recordsForSignature = [record]
            this.$bvModal.show('sign-document-modal')
          }
        } finally {
          this.loading = false
        }
        return
      }
      try {
        this.loading = true
        const res = await CalendarService.EGISZNextStep(record.id)
        if(res.data.id) {
          this.records[statusId].splice(index, 1)
          if(!this.records[res.data.status]) this.records[res.data.status] = []
          this.records[res.data.status].push(res.data)
        }
      } finally {
        this.loading = false
      }
    },
    async viewCDA(record) {
      const res = await CalendarService.getEgiszCDA(record.id)
      const blob = new Blob([res.data], { type: 'text/xml' })
      const blobURL = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = blobURL
      link.download = `cda_${record.id}.xml`
      link.click() // save as document because it does not open in browser
      window.URL.revokeObjectURL(blobURL)
    },
    async viewXML(record) {
      const res = await CalendarService.getEgiszXML(record.id)
      const blob = new Blob([res.data], { type: 'text/xml' })
      const blobURL = window.URL.createObjectURL(blob)
      window.open(blobURL) // open in new tab
      window.URL.revokeObjectURL(blobURL)
    },
    async downloadAll(status) {
      for(const rec of this.records[status]) {
        await this.viewCDA(rec)
      }
    },
    signAll(status) {
      this.recordsForSignature = this.records[status]
      this.$bvModal.show('sign-document-modal')
      console.log(status)
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
    this.loadList()
  }
}
</script>

<style scoped lang="scss">
.separator {
  height: 2px;
}
.column {
  min-width: 220px;
  font-size: 14px;
}
.record-card {
  svg {
    width: 18px;
  }
}
</style>
